import React from 'react'

import { Container, CloseIcon, NavHeader, Logo } from './styles'

import logo from 'src/assets/img/tdr_logo.png'

import {
  INDEX,
  PRODUCTS,
  WHAT_WE_DO,
  RESALE,
  CONTACT
} from 'src/config/navigationLinks'

const Drawer = ({ toggleSidebar, showSidebar }) => {
  return (
    <Container showSidebar={showSidebar}>
      <NavHeader>
        <Logo src={logo} alt="Logo da tdr" />
        <CloseIcon size={35} onClick={toggleSidebar} />
      </NavHeader>

      <ul>
        <li>
          <a href={INDEX} onClick={toggleSidebar}>
            Home
          </a>
        </li>

        <li>
          <a href={WHAT_WE_DO} onClick={toggleSidebar}>
            Sobre nós
          </a>
        </li>

        <li>
          <a href={RESALE} onClick={toggleSidebar}>
            Revenda
          </a>
        </li>

        <li>
          <a href={PRODUCTS} onClick={toggleSidebar}>
            Produtos
          </a>
        </li>

        <li>
          <a href={CONTACT} onClick={toggleSidebar}>
            Contato
          </a>
        </li>

        <li>
          <a href={INDEX} onClick={toggleSidebar}>
            Área do Cliente
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default Drawer
