import styled from 'styled-components'
import { MdMenu } from 'react-icons/md'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  padding-left: var(--padding-horizontal);
  color: #fff;
  z-index: 9;

  position: ${(props) => (props.isOnHero ? 'absolute' : 'fixed')};
  box-shadow: ${(props) =>
    props.isOnHero ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.25)'};
  background-color: ${(props) =>
    props.isOnHero ? 'transparent' : 'var(--primary)'};
  border-bottom-left-radius: ${(props) => (props.isOnHero ? 'none' : '20px')};
  border-bottom-right-radius: ${(props) => (props.isOnHero ? 'none' : '20px')};

  img.logo {
    height: 60px;
    flex-shrink: 0;
  }

  nav {
    display: none;

    ul {
      li,
      li a {
        color: #fff;
        cursor: pointer;
        transition: 0.3s all ease;
        border-bottom: 2px solid transparent;
        font-family: 'Gilroy-Bold';

        &:hover {
          border-bottom: 2px solid #fff;
        }
      }
    }

    button {
      border: 3px solid var(--secondary);
      border-radius: 8px;
      padding: 8px 1.5rem;
      color: #fff;
      cursor: pointer;
      font-family: 'Gilroy-Bold';

      &:hover {
        background-color: var(--secondary);
      }
    }
  }

  @media (min-width: 768px) {
    padding: 20px 50px;
    padding-left: var(--padding-horizontal);

    nav,
    ul {
      display: flex;
      align-items: center;
    }

    ul li {
      margin-right: 15px;
    }
  }
`

export const MenuIcon = styled(MdMenu)`
  width: 40px;
  height: 40px;
  fill: #fff;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
`
