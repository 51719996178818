import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: 0.3s all ease;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--padding-horizontal);

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s all ease;
  flex: 0.4;

  img.logo {
    height: 70px;
    transition: 0.3s all ease;
    margin-bottom: 30px;
  }

  div.info {
    max-width: 80%;

    p:first-child {
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      text-align: center;
      width: 100%;

      @media (min-width: 1024px) {
        text-align: start;
      }
    }
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`

export const NavList = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.5;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      transition: 0.3s all ease-out;

      p,
      a {
        color: #fff;
      }

      a {
        border-bottom: 1px solid transparent;
        transition: 0.3s all ease-out;
      }

      a:hover {
        border-bottom-color: #fff;
        transform: translateY(-3px);
      }

      p.small {
        font-size: 13px;
        font-family: 'Gilroy-Thin';
      }

      @media (min-width: 1024px) {
        align-items: flex-start;
      }
    }

    li.title {
      p {
        font-family: 'Gilroy-Bold';
        font-size: 20px;
      }
    }

    @media (min-width: 1024px) {
      align-items: flex-start;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    width: 60%;
    max-width: 500px;
    justify-content: space-around;
  }
`

export const SocialMediaList = styled.div`
  width: 60%;
  max-width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  transition: 0.3s all ease;
`

export const SocialMediaPrivacyPolicyWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 var(--padding-horizontal);
    margin-top: 35px;
  }
`

export const SocialMedia = styled.a`
  svg {
    fill: #fff;
  }
  transition: 0.3s all ease;
`

export const PrivacyPolicy = styled.a`
  margin: 20px 0;
  color: #fff;
`

export const Copyright = styled.div`
  width: 100%;
  background-color: #015796;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;

  p {
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-family: 'Gilroy-Thin';
  }
`
