import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BsChevronUp } from 'react-icons/bs'

import Drawer from 'src/components/Drawer'
import Navbar from 'src/components/Navbar'
import Footer from 'src/components/Footer'

import { Container, ButtonToTop } from './styles'

import GlobalStyles from 'src/styles/globalStyles'

const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [showButtonToTop, setShowButtonToTop] = useState(false)

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 1000) {
        setShowButtonToTop(true)
      } else {
        setShowButtonToTop(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })

  const handleSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TDR - Soluções em Tecnologia e Gestão Pública</title>
      </Helmet>

      <Navbar toggleSidebar={handleSidebar} />

      {children}

      <Footer />

      <Drawer toggleSidebar={handleSidebar} showSidebar={showSidebar} />

      <ButtonToTop
        visible={showButtonToTop}
        onClick={() => window.scrollTo(0, 0)}>
        <BsChevronUp size={30} />
      </ButtonToTop>

      <GlobalStyles />
    </Container>
  )
}

export default Layout
