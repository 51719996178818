import React from 'react'

import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaWhatsappSquare
} from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'

import {
  Container,
  ContentWrapper,
  Information,
  SocialMediaList,
  SocialMediaPrivacyPolicyWrapper,
  SocialMedia,
  NavList,
  PrivacyPolicy,
  Copyright
} from './styles'

import {
  FACEBOOK,
  LINKEDIN,
  INSTAGRAM,
  YOUTUBE,
  WHATSAPP
} from 'src/config/socialMediaLinks'

import tdrLogo from 'src/assets/img/Footer/Logo.png'

const Footer = () => {
  const ICON_SIZE = 30

  return (
    <Container id="footer">
      <ContentWrapper>
        <Information>
          <img className="logo" src={tdrLogo} alt="logo" />

          <div className="info">
            <p>
              A TDR está no mercado a 20 anos prestando serviço de excelência
              reconhecida por sua qualidade e cuidado com o cliente. Nosso
              objetivo é proporcionar a melhor experiência em tecnologia do
              mercado.
            </p>

            <p>Travessa Faia 27, São Francisco, Campo Grande</p>

            <p>CEP: 79118 071, Mato Grosso do Sul, Brasil</p>
          </div>
        </Information>

        <NavList>
          <ul>
            <li className="title">
              <p>Produtos</p>
            </li>

            <li>
              <a href="#">GSEA Saúde</a>
            </li>

            <li>
              <a href="#">GSEA Social</a>
            </li>

            <li>
              <a href="#">Tagnos Educação</a>
            </li>

            <li>
              <a href="#">AVA Tagnos</a>
            </li>

            <li>
              <a href="#">E-Cidadão</a>
            </li>

            <li>
              <a href="#">Gabinete do Prefeito</a>
            </li>
          </ul>

          <ul>
            <li className="title">
              <p>Serviços</p>
            </li>
            <li>
              <a href="#">Consultoria</a>
            </li>
            <li>
              <a href="#">Integrações</a>
            </li>
            <li>
              <a href="#">Desenvolvimento</a>
            </li>
            <li>
              <a href="#">Outsoursing</a>
            </li>
            <li>
              <a href="#">Treinamentos</a>
            </li>
          </ul>

          <ul>
            <li className="title">
              <p>Suporte</p>
            </li>
            <li>
              <a href="#">(67) 3027-6050</a>
              <p className="small">(Telefone e Whats App)</p>
            </li>
            <li>
              <a href="#">HelpDesk</a>
            </li>
            <li>
              <a href="#">Chat Online</a>
            </li>
            <li>
              <a href="#">TDR Treinamentos</a>
            </li>
            <li>
              <a href="#">Área do Cliente</a>
            </li>
          </ul>
        </NavList>
      </ContentWrapper>

      <SocialMediaPrivacyPolicyWrapper>
        <SocialMediaList>
          <SocialMedia href={FACEBOOK}>
            <FaFacebookSquare size={ICON_SIZE} />
          </SocialMedia>

          <SocialMedia href={LINKEDIN}>
            <FaLinkedin size={ICON_SIZE} />
          </SocialMedia>

          <SocialMedia href={INSTAGRAM}>
            <RiInstagramFill size={ICON_SIZE} />
          </SocialMedia>

          <SocialMedia href={YOUTUBE}>
            <FaYoutube size={ICON_SIZE} />
          </SocialMedia>

          <SocialMedia href={WHATSAPP}>
            <FaWhatsappSquare size={ICON_SIZE} />
          </SocialMedia>
        </SocialMediaList>

        <PrivacyPolicy href="#">Política de privacidade</PrivacyPolicy>
      </SocialMediaPrivacyPolicyWrapper>

      <Copyright>
        <p>
          Copyright © 2021 - Todos os direitos reservados à TDR Soluções em
          Tecnologia
        </p>
      </Copyright>
    </Container>
  )
}

export default Footer
