import { createGlobalStyle } from 'styled-components'
import GilroyThin from 'src/assets/fonts/Gilroy-Thin.ttf'
import GilroyLight from 'src/assets/fonts/Gilroy-Light.ttf'
import GilroyRegular from 'src/assets/fonts/Gilroy-Regular.ttf'
import GilroyBold from 'src/assets/fonts/Gilroy-Bold.ttf'
import GilroyBlack from 'src/assets/fonts/Gilroy-Black.ttf'
import GilroyBlackItalic from 'src/assets/fonts/Gilroy-BlackItalic.ttf'

export default createGlobalStyle`
  @font-face {
    font-family: "Gilroy-Thin";
    src: url(${GilroyThin});
  }

  @font-face {
    font-family: "Gilroy-Light";
    src: url(${GilroyLight});
  }

  @font-face {
    font-family: "Gilroy-Regular";
    src: url(${GilroyRegular});
  }
  
  @font-face {
    font-family: "Gilroy-Bold";
    src: url(${GilroyBold});
  }
  
  @font-face {
    font-family: "Gilroy-Black";
    src: url(${GilroyBlack});
  }
  
  @font-face {
    font-family: "Gilroy-BlackItalic";
    src: url(${GilroyBlackItalic});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1, h2 {
    line-height: 30px;
  }

  a {
    text-decoration: none;
  }

  html, body {
    min-height: 100%;
    scroll-behavior: smooth;
    background-color: var(--background);
    position: relative;
    overflow-x: hidden;
  }

  *, button, input {
    border: 0;
    background: none;
    font-family: "Gilroy-Light", Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #373435;
    outline: none;

    transition: .3s color ease-out, .3s background-color ease-out;
  }

  ul {
    list-style: none;
  }

  :root {
    --primary: #1B6BAD;
    --secondary: #E7AC36;
    --background: #fff;
    --padding-horizontal: 85px;
  }
`
