import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

export const Container = styled.div`
  background-color: var(--primary);
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.showSidebar ? '1' : '0')};
  transition: 0.3s all ease;
  z-index: 10;
  visibility: ${(props) => (props.showSidebar ? 'visible' : 'hidden')};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  padding: 30px;

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    overflow-y: scroll;

    li {
      font-size: 25px;
      margin: 25px 0;

      a {
        color: #fff;
        font-family: 'Gilroy-Bold';
      }
    }
  }
`

export const NavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Logo = styled.img`
  height: 35px;
`

export const CloseIcon = styled(MdClose)`
  fill: #fff;
  cursor: pointer;
`
