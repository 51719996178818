import styled from 'styled-components'

export const Container = styled.div``

export const ButtonToTop = styled.button`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  transition: 0.3s all ease-out;
  cursor: pointer;
  z-index: 5;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  &:hover {
    background-color: var(--primary);
    box-shadow: 0 3px 19px rgba(0, 0, 0, 0.8);
    transform: translateY(-4px);

    svg {
      fill: #fff;
    }
  }
`
