import React, { useState, useEffect } from 'react'
import logo from 'src/assets/img/tdr_logo.png'
import { Container, MenuIcon } from './styles'

import {
  INDEX,
  PRODUCTS,
  WHAT_WE_DO,
  RESALE,
  CONTACT
} from 'src/config/navigationLinks'

const Navbar = ({ toggleSidebar }) => {
  const [isOnHero, setIsOnHero] = useState(true)

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 0) {
        setIsOnHero(false)
      } else {
        setIsOnHero(true)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  })

  return (
    <Container isOnHero={isOnHero}>
      <img className="logo" src={logo} alt="Logo da tdr" />

      <nav>
        <ul>
          <li>
            <a href={INDEX}>Home</a>
          </li>

          <li>
            <a href={WHAT_WE_DO}>Sobre nós</a>
          </li>

          <li>
            <a href={RESALE}>Revenda</a>
          </li>

          <li>
            <a href={PRODUCTS}>Produtos</a>
          </li>

          <li>
            <a href={CONTACT}>Contato</a>
          </li>
        </ul>

        <button className="nav-button">Área do cliente</button>
      </nav>

      <MenuIcon onClick={toggleSidebar} />
    </Container>
  )
}

export default Navbar
